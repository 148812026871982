import { useEffect } from "react";
import { useState } from "react";
import { useSearchParams } from "next/navigation";

export const getUrlOrSession_ = (searchParams) => (key, sessionKey) => {
  if (searchParams.has(key)) {
    const value = searchParams.get(key);
    sessionStorage.setItem(sessionKey || key, value);
    return value;
  }
  return sessionStorage.getItem(key) || "";
};

function useCommonData() {
  const searchParams = useSearchParams();
  const [{ promotionId, saleId, innerfromid, fromid }, setSessionData] =
    useState({ promotionId: "", saleId: "", innerfromid: "", fromid: "" });
  const [{ inApp, inAndroid, inWechat, inIPhone, hideOpenApp }, setEnv] =
    useState({
      inApp: false,
      inAndroid: false,
      inWechat: false,
      inIPhone: false,
      hideOpenApp: false,
    });

  const getUrlOrSession = getUrlOrSession_(searchParams);

  useEffect(() => {
    const promotionId = getUrlOrSession("promotionId");
    const saleId = getUrlOrSession("saleId");
    const innerfromid = getUrlOrSession("innerfromid");
    const fromid =
      getUrlOrSession("fromid") || getUrlOrSession("from", "fromid");
    setSessionData({
      promotionId,
      saleId,
      innerfromid,
      fromid,
    });

    const inApp = navigator.userAgent.includes("taocheershoucheapp");
    const inWechat = navigator.userAgent
      ?.toLowerCase()
      .includes("micromessenger");
    const inAndroid = window.navigator.userAgent?.includes("Android");
    const inIPhone = window.navigator.userAgent?.includes("iPhone");
    const hideOpenApp = inApp || searchParams?.get("hideType") === 6;
    setEnv({
      inApp,
      inWechat,
      inAndroid,
      inIPhone,
      hideOpenApp,
    });
  }, []);

  return {
    promotionId,
    saleId,
    innerfromid,
    fromid,
    inApp,
    inWechat,
    inAndroid,
    inIPhone,
    hideOpenApp,
  };
}

export default useCommonData;
