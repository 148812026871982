"use client";
import { useRef, useEffect, useState } from "react";
import { useRouter, useSearchParams } from "next/navigation";
import { Toast } from "antd-mobile";

export default function useMyRouter() {
  const router = useRouter();
  const searchParams = useSearchParams();
  // console.info(Object.keys(router));
  // // const ToastRef = useRef();
  // const [isQQUC, setIsQQUC] = useState();

  // useEffect(() => {
  //   const ua = window.navigator.userAgent;
  //   if (
  //     ua.includes("iPhone") &&
  //     (ua.includes("QQBrowser") || ua.includes("UCBrowser"))
  //   ) {
  //     setIsQQUC(true);
  //   }

  //   // return () => {
  //   //     Toast.clear();
  //   //     ToastRef.current?.clear?.();
  //   // };
  // }, []);

  // return {
  //   push: (...res) => {
  //     // if (
  //     //     !res[0].includes("app") &&
  //     //     !res[0].includes("iframe") &&
  //     //     !res[0].includes("bangmai") &&
  //     //     !res[0].includes("http") && !isQQUC
  //     // ) {
  //     //     ToastRef.current = Toast.show({
  //     //         icon: "loading",
  //     //         duration: 3000
  //     //     });
  //     // }
  //     if (isQQUC) {
  //       window.location.href = res[0];
  //       return;
  //     }
  //     router.push(...res);
  //   },
  //   replace: (...res) => {
  //     // if (
  //     //     !res[0].includes("app") &&
  //     //     !res[0].includes("iframe") &&
  //     //     !res[0].includes("bangmai") &&
  //     //     !res[0].includes("http") && !isQQUC
  //     // ) {
  //     //     ToastRef.current = Toast.show({
  //     //         icon: "loading",
  //     //         duration: 3000,
  //     //     });
  //     // }
  //     if (isQQUC) {
  //       window.location.replace(res[0]);
  //       return;
  //     }
  //     router.replace(...res);
  //   },
  //   prefetch: (...res) => {
  //     //   if (
  //     //     !res[0].includes("app") &&
  //     //     !res[0].includes("iframe") &&
  //     //     !res[0].includes("bangmai") &&
  //     //     !res[0].includes("http") &&
  //     //     !isQQUC
  //     //   ) {
  //     //     ToastRef.current = Toast.show({
  //     //       icon: "loading",
  //     //       duration: 3000,
  //     //     });
  //     //   }
  //     //   if (isQQUC) {
  //     //     //
  //     //   }
  //     router.prefetch(...res);
  //   },
  //   back: () => {
  //     // ToastRef.current = Toast.show({
  //     //   icon: "loading",
  //     //   duration: 3000
  //     // });
  //     if (isQQUC) {
  //       window.history.back();
  //       setTimeout(() => {
  //         if (location.search.includes("shallow")) {
  //           window.location.reload();
  //         }
  //       }, 20);
  //       return;
  //     }
  //     router.back();
  //     setTimeout(() => {
  //       if (location.search.includes("shallow")) {
  //         window.location.reload();
  //       }
  //     }, 20);
  //   },
  //   refresh: () => {
  //     if (!isQQUC) {
  //       // ToastRef.current = Toast.show({
  //       //   icon: "loading",
  //       //   duration: 3000,
  //       // });
  //     }
  //     if (isQQUC) {
  //       window.location.reload();
  //       return;
  //     }
  //     router.refresh();
  //   },
  //   forward: () => {
  //     if (!isQQUC) {
  //       // ToastRef.current = Toast.show({
  //       //   icon: "loading",
  //       //   duration: 3000,
  //       // });
  //     }
  //     if (isQQUC) {
  //       window.location.forward();
  //       return;
  //     }
  //     router.forward();
  //   },
  // };
  // ['back', 'forward', 'prefetch', 'replace', 'push', 'refresh', 'fastRefresh']
  const link = (href) => {
    const nextUrl = new URL(href, window.location);

    // 如果当前url中有门店和城市 跳转的url中没有 就带上
    // 为了解决命中浏览器缓存之后 不走中间件跳转的问题
    if (
      !nextUrl.searchParams.has("city") &&
      !nextUrl.searchParams.has("storeId") &&
      searchParams.has("city") &&
      searchParams.has("storeId")
    ) {
      nextUrl.searchParams.set("city", searchParams.get("city"));
      nextUrl.searchParams.set("storeId", searchParams.get("storeId"));
    }

    console.info(nextUrl.href);
    window.location.href = nextUrl.href;
  };

  return {
    back: router.back,
    replace: link,
    push: link,
    prefetch: router.prefetch,
    forward: router.forward,
    refresh: router.refresh,
    fastRefresh: router.fastRefresh,
  };
}
