/**
 *  @Author: qp
 *  @Date: 2023-11-28 21:40:50
 * @Last Modified by: qp
 * @Last Modified time: 2024-08-02 10:49:18
 *  @name 易车埋点
 **/

import { useEffect, useState } from "react";
import { useSearchParams } from "next/navigation";
import Cookie from "@/utils/Cookie";
import fetch from "@/utils/fetch";
import useUser from "./useUser";
import { useGlobal } from "@/components/Global";
import { getUrlOrSession_ } from "@/hooks/useCommonData";

/**
 * 曝光
 */
function initExposure(data) {
  // 上报逻辑
  const elements = document.getElementsByClassName("sdk-exposure-spa");
  const { clientHeight, clientWidth } = document.documentElement;
  const current = new Date().getTime();
  for (let i = 0; i < elements.length; i++) {
    const ele = elements[i];
    const { top, bottom, left, right } = ele.getBoundingClientRect();
    // 获取屏幕内的元素
    if (
      top >= 0 &&
      bottom <= clientHeight &&
      left >= 0 &&
      right <= clientWidth
    ) {
      // 第一次进入 设置进入时间
      if (!ele.enterTime) {
        ele.enterTime = new Date().getTime();
      }
      // 没有上报过 且 已经在屏幕内2秒 上报
      if (!ele.exposured && current - ele.enterTime >= 2000) {
        window.$SDK_ALL.sendPageExposure({
          data: {
            ...data,
            ...JSON.parse(ele.getAttribute("data-exposure-extend")),
          },
        });
        ele.exposured = true;
      }
    } else {
      // 屏幕外的清空时间
      ele.enterTime = undefined;
    }
  }
}

let myInterval = null;

export const sourceToString = (source) =>
  source == 1 ? "偏好" : source == 2 ? "热门" : "普通";

// export const getUrlOrSession_ = (searchParams) => (key, sessionKey) => {
//   if (searchParams.has(key)) {
//     const value = searchParams.get(key);
//     sessionStorage.setItem(sessionKey || key, value);
//     return value;
//   }
//   return sessionStorage.getItem(key) || "";
// };

const useReport = (page_id, data) => {
  // console.info("page_id", page_id);
  // const [report, setReport] = useSessionStorage(["report"]);

  const searchParams = useSearchParams();
  // const { promotionId, saleId, innerfromid, fromid } = useCommonData();

  // const getUrlOrSession = getUrlOrSession_(searchParams);

  const { user } = useUser();
  const [reportJSLoaded, setReportJSLoaded] = useGlobal(["reportJSLoaded"]);
  const [pageId, setPageId] = useGlobal(["pageId"]);

  const getGlobalParams = async () => {
    const getUrlOrSession = getUrlOrSession_(searchParams);

    const promotionId = getUrlOrSession("promotionId");
    const saleId = getUrlOrSession("saleId");
    const innerfromid = getUrlOrSession("innerfromid");
    const fromid =
      getUrlOrSession("fromid") || getUrlOrSession("from", "fromid");
    const city = JSON.parse(Cookie.get("city") || "{}");

    // 当前城市
    let city_id = "";
    if (city?.cityId) {
      city_id = city?.cityId;
    }

    // 定位城市
    let ipCity = null;
    try {
      ipCity = JSON.parse(Cookie.get("ipCity") || "null");
    } catch {
      ipCity = null;
    }
    if (!ipCity) {
      ipCity = await fetch("/c-city-consumer/city/getiplocation");
      if (ipCity) {
        Cookie.set("ipCity", JSON.stringify(ipCity));
      }
    }
    const cityId = ipCity?.cityId || "";

    const user_id = user?.loanUserId || "";

    const pageIds = sessionStorage.getItem("pageIds");
    let pfrom_id = "";
    if (pageIds) {
      pfrom_id = pageIds?.split(",")[1];
      // if (pageId.length > 1) pfrom_id = pageId[1];
    }
    return {
      fromid,
      innerfromid,
      cityId,
      city_id,
      user_id,
      pfrom_id,
      saleId,
      promotionId,
    };
  };

  useEffect(() => {
    const onload = () => {
      window?.TC?.GA?.yiche({
        businessType: "taocheche",
        env: process.env.RUNTIME_ENV == "test" ? "dev" : "prod",
      }).then(() => {
        setReportJSLoaded(true);
      });
    };
    if (!reportJSLoaded) {
      const $js_sdk = document.getElementById("ga-yiche");
      if ($js_sdk) {
        $js_sdk.onload = onload;
      } else {
        const ms = new Date().getTime();
        const src =
          "https://static.taocheche.com/taoche-c/lib/ga/index.min.js?v=" + ms;

        const ele = document.createElement("script");

        ele.type = "text/javascript";
        ele.id = "ga-yiche";
        ele.src = src;
        document.getElementsByTagName("head")[0].appendChild(ele);
        ele.onload = onload;
      }
    }
  }, []);

  useEffect(() => {
    if (page_id && reportJSLoaded) {
      let pageIds = sessionStorage.getItem("pageIds");
      if (pageIds) {
        pageIds = page_id + "," + pageIds;
      } else {
        pageIds = page_id;
      }
      sessionStorage.setItem("pageIds", pageIds);
      // console.info("page_id", page_id);
      // console.info("reportJSLoaded", reportJSLoaded);
      setPageId([page_id].concat(pageId || []));

      getGlobalParams().then((globalParams) => {
        window?.TC?.GA?.sendPage({
          data: {
            ...window?.tcc?.globalQuery,
            page_id,
            ...globalParams,
            ...data,
          },
        });

        myInterval = setInterval(
          () =>
            initExposure({
              page_id,
              ...globalParams,
              ...data,
            }),
          1000
        );
      });
    }
    return () => {
      if (page_id && reportJSLoaded) {
        clearInterval(myInterval);
      }
    };
  }, [page_id, reportJSLoaded]);

  const reportClick = (event_id, data) => {
    getGlobalParams().then((globalParams) => {
      let page_id = "";
      if (Array.isArray(pageId) && pageId.length > 0) {
        page_id = pageId[0];
      }
      window?.$SDK_ALL?.sendPageEvent({
        event_id,
        data: {
          page_id,
          ...globalParams,
          ...data,
        },
      });
    });
  };

  return { reportClick };
};

export default useReport;
